import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
// import Layout from '../components/Layout';
import SEO from '../components/seo';

const NotFoundPage = props => {
  const {
    data: {
      markdownRemark: {
        frontmatter: { image },
      },
    },
  } = props;
  return (
    <div>
      <SEO pageTitle="404: Not found" />
      <BackgroundImage Tag="section" className="bg-notfound" fluid={image.childImageSharp.fluid}>
        <Container fluid>
          <Row className="notfound-row w-100">
            <Col xs={12}>
              <div className="text-center d-flex justify-content-center align-items-center flex-column w-100 h-100">
                <h1 className="text-extra-bold text-white">404</h1>
                <h4 className="text-semibold text-white py-1">There is nothing here...</h4>
                <h6 className="text-semibold text-white py-3">
                  The page you are looking for no longer exists.
                </h6>
                <Link
                  to="/"
                  className="d-block px-5 py-2 bg-appcolor-light text-white text-bold gotohome"
                >
                  Go to Home
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </div>
  );
};

export default NotFoundPage;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "notfound" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 512, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
